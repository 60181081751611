import React from "react"
import CreateProfileForm from "../../components/forms/createprofile"
import Layout from "../../components/layouts/layout"

const NewResearcher = () => {
	return (
		<Layout title="Create Researcher Profile">
			<CreateProfileForm
				source="/researcher/new"
				roles="Researcher"
			/>
    </Layout>
  )
}

export default NewResearcher